.sure-modal-main {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.sure_model_btn {
  border: none;
  margin: 0px 10px;
  width: 100px;
}
